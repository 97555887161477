import { FooterLinkType } from '@pancakeswap/uikit'
import { ContextApi } from 'contexts/Localization/types'

export const footerLinks: (t: ContextApi['t']) => FooterLinkType[] = (t) => [
  {
    label: t('About'),
    items: [
      {
        label: t('Email Us'),
        href: 'https://docs.planet.finance/partnerships/how-to-build-with-us',
      },
      {
        label: t('Blog'),
        href: 'https://planetfi.medium.com/',
      },
      {
        label: t('Docs'),
        href: 'https://docs.planet.finance/',
      },
    ],
  },
  {
    label: t('Community'),
    items: [
      {
        label: t('Medium'),
        href: 'https://planetfi.medium.com/',
      },
      {
        label: t('Telegram'),
        href: 'https://t.me/planet_finance',
      },
      {
        label: t('Twitter'),
        href: 'https://twitter.com/planet_finance',
      },
      {
        label: t('Discord'),
        href: 'https://discord.com/invite/wkbDJrsAbX',
      },
    ],
  },
  {
    label: t('Learn More'),
    items: [
      {
        label: t('Audit'),
        href: 'https://github.com/HalbornSecurity/PublicReports/blob/master/Solidity%20Smart%20Contract%20Audits/Planet_Finance_Smart_Contract_Security_Audit_Halborn_v1_1.pdf',
      },
      {
        label: t('Documentation'),
        href: 'https://docs.planet.finance/',
      },
      {
        label: 'Github',
        href: 'https://github.com/planetfinance',
      }
    ],
  },
  {
    label: t('Protocol'),
    items: [
      {
        label: 'AQUA',
        href: 'https://docs.planet.finance/tokenomics/aqua',
      },
      {
        label: t('GAMMA'),
        href: 'https://docs.planet.finance/tokenomics/gamma',
      },
      {
        label: t('Tokenomics'),
        href: 'https://docs.planet.finance/tokenomics/key-details',
      },
      {
        label: t('Contract Address'),
        href: 'https://bscscan.com/token/0x72b7d61e8fc8cf971960dd9cfa59b8c829d91991',
      },
      {
        label: t('Burn Address'),
        href: 'https://bscscan.com/token/0x72b7d61e8fc8cf971960dd9cfa59b8c829d91991?a=0x000000000000000000000000000000000000dEaD',
      },
    ],
  },
]
