import { MenuItemsType, MenuItemType } from '@pancakeswap/uikit'
import { ContextApi } from 'contexts/Localization/types'

export type ConfigMenuItemsType = MenuItemsType & { hideSubNav?: boolean }

const config: (t: ContextApi['t']) => ConfigMenuItemsType[] = (t) => [
  {
    label: t('Pools'),
    icon: 'Pools',
    href: 'https://app.planet.finance/pools',
    showOnMobile: true,
    showItemsOnMobile: false,
    type: MenuItemType.EXTERNAL_LINK,
    items: [
      
    ],
  },
  {
    label: t('Lending'),
    icon: 'Lending',
    href: 'https://app.planet.finance/lending',
    showOnMobile: true,
    showItemsOnMobile: false,
    type: MenuItemType.EXTERNAL_LINK,
    items: [
      
    ],
  },
  {
    label: t('Swap'),
    icon: 'Swap',
    href: 'https://app.planet.finance/swap',
    showOnMobile: true,
    showItemsOnMobile: false,
    type: MenuItemType.EXTERNAL_LINK,
    items: [
      
    ],
  },
  {
    label: t('Perpetuals'),
    icon: 'Perpetuals',
    href: 'https://perp.planet.finance/',
    showOnMobile: true,
    showItemsOnMobile: false,
    type: MenuItemType.EXTERNAL_LINK,
    items: [
      
    ],
  },
  {
    label: t('Analytics'),
    icon: 'Analytics',
    href: '/',
    showOnMobile: false,
    showItemsOnMobile: false,
    items: [
      
    ],
  },
  /*
    label: t('Metrics'),
    icon: 'Swap',
    href: 'https://blue.planetfinance.io/aqua-profit',
    showOnMobile: false,
    showItemsOnMobile: false,
    type: MenuItemType.EXTERNAL_LINK,
    items: [
      
    ],
  */
  {
    label: t('Governance'),
    icon: 'Governance',
    href: 'https://vote.planet.finance/#/',
    showOnMobile: true,
    showItemsOnMobile: false,
    type: MenuItemType.EXTERNAL_LINK,
    items: [
      
    ],
  }
]

export default config
