import React from 'react'
import { useLocation } from 'react-router'
import { Menu as UikitMenu } from '@pancakeswap/uikit'
import { languageList } from 'config/localization/languages'
import { useTranslation } from 'contexts/Localization'
import useTheme from 'hooks/useTheme'
import {
  useAllTokenData
} from 'state/info/hooks'
import { usePriceCakeBusd } from 'state/farms/hooks'
import config from './config/config'
import { getActiveMenuItem, getActiveSubMenuItem } from './utils'
import { footerLinks } from './config/footerConfig'

const Menu = (props) => {
  const { isDark, toggleTheme } = useTheme()
  const cakePriceUsd = usePriceCakeBusd()
  const { currentLanguage, setLanguage, t } = useTranslation()
  const { pathname } = useLocation()

  const activeMenuItem = getActiveMenuItem({ menuConfig: config(t), pathname })
  const activeSubMenuItem = getActiveSubMenuItem({ menuItem: activeMenuItem, pathname })

  const allTokens = useAllTokenData()

  let aquaPrice = 0;
  let gammaPrice = 0;
  if(allTokens && allTokens['0x72b7d61e8fc8cf971960dd9cfa59b8c829d91991'] && allTokens['0x72b7d61e8fc8cf971960dd9cfa59b8c829d91991'].data && allTokens['0x72b7d61e8fc8cf971960dd9cfa59b8c829d91991'].data.priceUSD)
  {
    aquaPrice = allTokens['0x72b7d61e8fc8cf971960dd9cfa59b8c829d91991'].data.priceUSD;
  }

  if(allTokens && allTokens['0xb3cb6d2f8f2fde203a022201c81a96c167607f15'] && allTokens['0xb3cb6d2f8f2fde203a022201c81a96c167607f15'].data && allTokens['0xb3cb6d2f8f2fde203a022201c81a96c167607f15'].data.priceUSD)
  {
    gammaPrice = allTokens['0xb3cb6d2f8f2fde203a022201c81a96c167607f15'].data.priceUSD;
  }

  return (
    <UikitMenu
      isDark={isDark}
      toggleTheme={toggleTheme}
      currentLang={currentLanguage.code}
      langs={languageList}
      setLang={setLanguage}
      cakePriceUsd={aquaPrice}
      gammaPriceUsd={gammaPrice}
      links={config(t)}
      subLinks={activeMenuItem?.hideSubNav ? [] : activeMenuItem?.items}
      footerLinks={footerLinks(t)}
      activeItem={activeMenuItem?.href}
      activeSubItem={activeSubMenuItem?.href}
      buyCakeLabel={t('Buy CAKE')}
      {...props}
    />
  )
}

export default Menu
