import { ContextApi } from 'contexts/Localization/types'
import { PageMeta } from './types'

export const DEFAULT_META: PageMeta = {
  title: 'Planet Finance',
  description:
    'The most popular AMM on BSC by user count! Earn Aqua through yield farming or win it in the Lottery, then stake it in Syrup Pools to earn more tokens! Initial Farm Offerings (new token launch model pioneered by PancakeSwap), NFTs, and more, on a platform you can trust.',
  image: 'https://app.planet.finance/static/media/blue-icon.987a806f.svg',
}

export const getCustomMeta = (path: string, t: ContextApi['t']): PageMeta => {
  let basePath
  if (path.startsWith('/swap')) {
    basePath = '/swap'
  } else if (path.startsWith('/add')) {
    basePath = '/add'
  } else if (path.startsWith('/remove')) {
    basePath = '/remove'
  } else if (path.startsWith('/teams')) {
    basePath = '/teams'
  } else if (path.startsWith('/voting/proposal') && path !== '/voting/proposal/create') {
    basePath = '/voting/proposal'
  } else if (path.startsWith('/nfts/collections')) {
    basePath = '/nfts/collections'
  } else if (path.startsWith('/nfts/profile')) {
    basePath = '/nfts/profile'
  } else if (path.startsWith('/pancake-squad')) {
    basePath = '/pancake-squad'
  } else {
    basePath = path
  }

  switch (basePath) {
    case '/':
      return {
        title: `${t('Overview')} | ${t('Planet Finance Analytics')}`,
        description: 'View statistics for Planet Finance.',
      }
    case 'pools':
      return {
        title: `${t('Pools')} | ${t('Planet Finance Analytics')}`,
        description: 'View statistics for Planet Finance.',
      }
    case '/tokens':
      return {
        title: `${t('Tokens')} | ${t('Planet Finance Analytics')}`,
        description: 'View statistics for Planet Finance.',
      }
    default:
      return null
  }
}
